import React, {useState} from 'react';


const NumericValue = props => {

    const { answers, question, readOnly, handleNewAnswer } = props
    const [answer,setAnswer] = useState((answers && answers.length > 0) ? answers[0] : {answerNumeric: question.min});
    const [value, setValue] = useState((answers && answers.length > 0) ? answers[0].answerNumeric : question.min);

    const handleChange = (e) => {
        if(!readOnly) {
            if(e.target.value.length > 0) {
                // if(!isNaN(e.target.value)) {
                const tempAnswer = {
                    questionId: question.id,
                    answerText: parseInt(e.target.value).toString(),
                    assessmentId: 0,
                    answerNumeric: parseInt(e.target.value),
                    answerDate: null,
                    templateAnswer: null
                }
                setAnswer(tempAnswer);
                setValue(e.target.value);

                if(handleNewAnswer)
                    handleNewAnswer([answer]);
                // }
            }
            else setValue(e.target.value);
        }
    }

    return (
            <div>
                <input
                        type="text" inputMode="numeric" pattern="[0-9]*"
                        className="form-control"
                        min={question.min}
                        max={question.max}
                        required={question.required}
                        defaultValue={value}
                        step={question.steps}
                        onChange={handleChange}
                        onFocus={(event) => event.target.select()}
                />
            </div>
    )
}
export default NumericValue;