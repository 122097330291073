import React, {useState} from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import './Questionnaire.css';
import LandingPage from "./components/LandingPage";
import {UserContext} from "./context/UserContext"

function Questionnaire() {
    const [user, updateUser] = useState(null);

    function setUser(user) {
        updateUser(user);
    }

    return (

            <UserContext.Provider value={{user, setUser}}>
                <HashRouter>
                    {/*basename={'/assessment'}>*/}
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/:urlKey" element={<LandingPage/>}/>
                    </Routes>
                </HashRouter>
            </UserContext.Provider>

    );
}

export default Questionnaire;
