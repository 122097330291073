import React, {Component} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";
import './Selection.css'

class MultipleSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templateAnswers: [...props.question.answers],
            answers: props.answers,
            readOnly: props.readOnly
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers))
            this.setState({answers: this.props.answers})
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})

    }


    handleChange(event) {

        if(!this.state.readOnly) {
            const templateAnswer = this.state.templateAnswers.find(item => item.id === parseInt(event.target.id));
            const answers = this.state.answers;
            if(event.target.checked) { //just checked
                const answer = {
                    questionId: this.props.question.id,
                    answerText: templateAnswer.hasFreeText ? "" : templateAnswer.answer,
                    assessmentTemplateAnswerId: parseInt(event.target.id)
                }
                answers.push(answer);
            }
            else {
                const index = this.state.answers.findIndex(item => item.assessmentTemplateAnswerId === parseInt(event.target.id));
                if(index > -1) {
                    answers.splice(index, 1);
                }
            }
            this.setState({answers: answers});
            if(this.props.handleNewAnswer)
                this.props.handleNewAnswer(answers);
        }

    }

    saveAnswers() {
        if(this.props.handleNewAnswer)
            this.props.handleNewAnswer(this.state.answers);

    }

    render() {
        return (
                <div className={"selection"}>
                    {this.state.templateAnswers.map((templateAnswer, index) => {
                        const answerIndex = this.state.answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id);
                        const checked = answerIndex > -1;
                        const rootClass = this.state.readOnly ? 'Checkbox read-only' : 'Checkbox';
                        return (<div className="answer mx-3" key={index.toString()}>
                                    {/*<div className="form-check form-check-inline mx-3">*/}
                                    <FormControlLabel
                                            classes={{root: 'FormControlLabel'}}
                                            label={templateAnswer.answer}
                                            control={
                                                <Checkbox
                                                        classes={{root: rootClass, checked: "checked"}}
                                                        checked={checked}
                                                        onChange={this.handleChange}
                                                        id={templateAnswer.id.toString()}
                                                />
                                            }
                                    />
                                    {(answerIndex > -1 && templateAnswer.hasFreeText) ?
                                     <span>
                                 {this.state.readOnly ? answerIndex > -1 ? this.state.answers[answerIndex].answerText : ""
                                                      : <input
                                          onKeyUp={(e) => this.handleKeyUp(e, answerIndex)}
                                          defaultValue={answerIndex > -1 ? this.state.answers[answerIndex].answerText : ""}
                                          onBlur={() => this.saveAnswers()}
                                  />}
                                 </span> : null}
                                </div>

                        )
                    })}

                </div>
        )
    }

    handleKeyUp(e, index) {
        if(index > -1) {
            const answers = this.state.answers;
            answers[index].answerText = e.target.value;
            this.setState({answers: answers})
        }

    }
}

export default MultipleSelection;